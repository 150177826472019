import { FirebaseApp, FirebaseError } from 'firebase/app'
import {
  Auth,
  EmailAuthProvider,
  Unsubscribe,
  User,
  browserLocalPersistence,
  browserSessionPersistence,
  confirmPasswordReset,
  getAuth,
  getIdToken,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
  verifyPasswordResetCode,
} from 'firebase/auth'

import { getApolloClient } from '~shared/services/apollo/apollo'
import { leaveBreadcrumb, notify } from '~shared/services/bugsnag/client'
import { resolveAuthData } from '~shared/services/firebase/auth/auth'
import { AuthUserData } from '~shared/services/firebase/auth/types'
import { createLogger } from '~shared/utils/log'

import { getFirebaseInstance } from '../firebase'

import useAuthStore from './store'

const log = createLogger('Authenticator')

export type FirebaseUser = User

export class Authenticator {
  static instance: FirebaseApp | undefined = undefined

  static setInstance(instance: FirebaseApp): void {
    this.instance = instance
  }

  static clearInstance(): void {
    this.instance = undefined
  }

  static app(): FirebaseApp {
    return this.instance ?? getFirebaseInstance()
  }

  static auth(): Auth {
    return getAuth(this.app())
  }

  static async logout(): Promise<void> {
    log('Logout', 'Attempting...')
    await this.auth().signOut()
    await getApolloClient().clearStore()
    useAuthStore.getState().reset()
    log('Logout', 'Ended')
  }

  static async signIn(
    email: string,
    password: string,
    rememberMe?: boolean,
  ): Promise<AuthUserData | null> {
    try {
      useAuthStore.getState().latch.lock()
      if (rememberMe !== undefined) {
        const persistence = rememberMe
          ? browserLocalPersistence
          : browserSessionPersistence
        await this.auth().setPersistence(persistence)
      }
      log('signIn', 'Attempting...')
      const credential = await signInWithEmailAndPassword(
        this.auth(),
        email,
        password,
      )
      const user = await resolveAuthData(credential.user)
      useAuthStore.getState().setData(user)
      useAuthStore.getState().latch.release()

      return useAuthStore.getState().data?.user ?? null
    } catch (error) {
      const isFirebaseError = error instanceof FirebaseError
      leaveBreadcrumb('Authenticator.signIn Failed', {
        email,
      })
      if (isFirebaseError) notify(new FirebaseSignInError(email, error))
      return null
    }
  }

  static async updatePassword(
    email: string,
    oldPassword: string,
    newPassword: string,
  ): Promise<void> {
    try {
      log('updatePassword', 'Attempting...')
      const authCred = EmailAuthProvider.credential(email, oldPassword)
      const authUser = this.auth().currentUser
      if (!authUser) throw new Error('Could not update password!')
      await reauthenticateWithCredential(authUser, authCred)
      log('updatePassword', 'Reauthenticated...')
      await updatePassword(authUser, newPassword)
      log('updatePassword', 'Ended')
    } catch (error) {
      log('updatePassword', 'Failed', error)
      notify(error)
      throw new Error(
        'Could not update your password. Please check to ensure your current password is entered correctly.',
      )
    }
  }

  static verifyPasswordResetCode(code: string): Promise<string> {
    return verifyPasswordResetCode(this.auth(), code)
  }

  static confirmPasswordReset(code: string, password: string): Promise<void> {
    return confirmPasswordReset(this.auth(), code, password)
  }

  static async getSessionToken(
    forceRefresh = false,
  ): Promise<string | undefined> {
    const currentUser = this.auth().currentUser
    if (!currentUser) return undefined
    return await getIdToken(currentUser, forceRefresh)
  }

  static getUser(): FirebaseUser | null {
    return this.auth().currentUser
  }

  static async refreshToken(): Promise<string | undefined> {
    try {
      return await this.getSessionToken()
    } catch (error) {
      notify(error)
      return undefined
    }
  }

  static sendPasswordResetEmail(
    email: string,
    url = window.location.href,
  ): Promise<void> {
    return sendPasswordResetEmail(this.auth(), email, {
      handleCodeInApp: true,
      url,
    })
  }

  static onSessionTokenChange(
    handler: (user: FirebaseUser | null) => Promise<void>,
  ): Unsubscribe {
    return this.auth().onIdTokenChanged(handler)
  }
}

class FirebaseSignInError extends Error {
  constructor(email: string, error: FirebaseError) {
    super(`${email} (${error.code})`, { cause: error })
    this.name = 'FirebaseSignInError'
  }
}
