import { Event as BugsnagEvent } from '@bugsnag/js'
import { get } from 'lodash'

import { isClientSide } from '~shared/services/context'

type IgnoreErrorFn = (event: BugsnagEvent) => boolean

/**
 * We don't want to be notified of these errors on Safari, as they are a result of a bug in the AbortController spec
 * - https://bugs.webkit.org/show_bug.cgi?id=215771
 */
export const isSafariAbortError: IgnoreErrorFn = (event) => {
  const vendor = isClientSide() ? get(navigator, 'vendor', '') : ''
  const className = get(event, 'originalError.constructor.name', '')
  const message = get(event, 'originalError.message', '')
  return (
    vendor.includes('Apple') &&
    className === 'AbortError' &&
    message.includes('Fetch is aborted')
  )
}

/**
 * Some chrome extensions throw illegal invocation errors when injecting code into the page,
 * which is causing lots of noise in our error reporting. This error is pretty tough for us
 * to intentionally implement as we use TypeScript and we don't have any direct evals in our
 * own code.
 */
export const isIllegalInvocation: IgnoreErrorFn = (event) => {
  const message = String(get(event, 'originalError.message', ''))
  return message.includes('Illegal invocation')
}

const ignoreErrorFns: IgnoreErrorFn[] = [
  isSafariAbortError,
  isIllegalInvocation,
]
export const isIgnoredError: IgnoreErrorFn = (event) =>
  ignoreErrorFns.some((fn) => fn(event))
