import Bugsnag, {
  BreadcrumbType,
  Event as BugsnagEvent,
  Client,
  Event,
  NotifiableError,
  OnErrorCallback,
} from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import * as React from 'react'

import Identity from '~shared/components/Identity/Identity'
import { APP_ENV, AppEnv } from '~shared/config/app'
import { BUGSNAG_CLIENT_KEY, BUGSNAG_SERVER_KEY } from '~shared/config/bugsnag'
import {
  APP_BUILD_SHA,
  APP_BUILD_TAG,
  APP_VERSION,
} from '~shared/config/version'
import { isIgnoredError } from '~shared/services/bugsnag/onError'
import { isClientSide } from '~shared/services/context'

const apiKey = BUGSNAG_SERVER_KEY || BUGSNAG_CLIENT_KEY

export let bugsnagClient: Client | undefined = undefined
if (!bugsnagClient && apiKey) {
  bugsnagClient = Bugsnag.start({
    apiKey,
    plugins: [new BugsnagPluginReact()],
    releaseStage: APP_ENV,
    appVersion: APP_VERSION,
    appType: isClientSide() ? 'client' : 'server',
    // Do not report local/unknown env errors as these clog up the reporting
    enabledReleaseStages: ['production', 'demo', 'qa', 'dev'] as AppEnv[],
    metadata: {
      version: {
        version: APP_VERSION,
        build: APP_BUILD_TAG,
        sha: APP_BUILD_SHA,
      },
    },
    onError: (event: Event) => {
      if (isIgnoredError(event)) return false
    },
  })
}

export const getErrorBoundary = (): BugsnagErrorBoundary => {
  const plugin = bugsnagClient?.getPlugin('react')
  if (!plugin) return Identity
  return plugin.createErrorBoundary(React)
}

export const leaveBreadcrumb = (
  message: string,
  metadata?:
    | {
        [key: string]: unknown
      }
    | undefined,
  type?: BreadcrumbType | undefined,
): void => {
  return bugsnagClient?.leaveBreadcrumb(message, metadata, type)
}

export const isErrorClassWithMessage = (
  item: unknown,
): item is { errorClass: string; errorMessage: string } => {
  if (
    item &&
    typeof item === 'object' &&
    typeof (item as Partial<{ errorClass: string }>).errorClass === 'string' &&
    typeof (item as Partial<{ errorMessage: string }>).errorMessage === 'string'
  )
    return true
  return false
}

export const isMessageAndName = (
  item: unknown,
): item is { name: string; message: string } => {
  if (
    item &&
    typeof item === 'object' &&
    typeof (item as Partial<{ name: string }>).name === 'string' &&
    typeof (item as Partial<{ message: string }>).message === 'string'
  )
    return true
  return false
}

export const isNotifiableError = (item: unknown): item is NotifiableError => {
  if (typeof item === 'string') return true
  if (item instanceof Error) return true
  if (isErrorClassWithMessage(item)) return true
  if (isMessageAndName(item)) return true
  return false
}

export const notify = (
  error: unknown,
  onError?: OnErrorCallback | undefined,
  cb?: ((err: unknown, event: BugsnagEvent) => void) | undefined,
): void => {
  if (isNotifiableError(error)) {
    return bugsnagClient?.notify(error, onError, cb)
  }
}

export const setUser = (
  id?: string | undefined,
  email?: string | undefined,
  name?: string | undefined,
): void => {
  return bugsnagClient?.setUser(id, email, name)
}

export const addMetadata = (
  section: string,
  values: {
    [key: string]: unknown
  },
): void => {
  return bugsnagClient?.addMetadata(section, values)
}
